import { Link } from 'gatsby'
import React from 'react'
import { Drawer, Button } from 'antd'
import './header.css'

class Header extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      drawerVisible: false
    }
  }

  showDrawer = () => {
    this.setState({
      drawerVisible: true
    })
  }

  onClose = () => {
    this.setState({
      drawerVisible: false
    })
  }

  scrollTo = (no) => {
    this.props.onPageChange(no, 'manual')
    this.onClose()
  }

  render () {
    return (
      <header className={'header'}>
        <Link
          to="/"
          className={'home'}
        >
          {'Serchis Creative'}
        </Link>
        <Button className={'burger'} onClick={this.showDrawer} type="primary" shape="round" icon="menu" size={'large'} />
        <div className={'tabs__container'}>
          <a className={'tab'} onClick={() => this.scrollTo(1)}>Work</a>
          <a className={'tab'} onClick={() => this.scrollTo(4)}>About</a>
          <a className={'tab'} onClick={() => this.scrollTo(5)}>Services</a>
          <a className={'tab'} onClick={() => this.scrollTo(7)}>Contact</a>
        </div>
        <Drawer
          placement="right"
          className={'drawer'}
          closable={false}
          onClose={this.onClose}
          visible={this.state.drawerVisible}
        >
          <p className={'side-menu-item'} onClick={() => this.scrollTo(1)}>
            Work
          </p>
          <p className={'side-menu-item'} onClick={() => this.scrollTo(2)}>
            About
          </p>
          <p className={'side-menu-item'} onClick={() => this.scrollTo(5)}>
            Services
          </p>
          <p className={'side-menu-item'} onClick={() => this.scrollTo(7)}>
            Contact
          </p>
        </Drawer>
      </header>
    )
  }
}

export default Header
